import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './arrow-icon.module.scss';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';

export interface ArrowIconProps {
  isPointingDown: boolean,
  anchorLinkTo: string
}

export default class ArrowIcon extends React.Component<ArrowIconProps> {

  public render() {
    return (
      <div
        className={styles.arrowAnimation}
        data-sal="fade"
        data-sal-delay="300"
        data-sal-duration="1500"
        data-sal-easing="easy"
      >
        <AnchorLink className={styles.arrowLink} to={this.props.anchorLinkTo} stripHash={true}>
          { this.props.isPointingDown ? (
            <FontAwesomeIcon icon={faAngleDoubleDown} />
          ) : (
            <FontAwesomeIcon icon={faAngleDoubleUp} />
          )}
        </AnchorLink>
      </div>
    );
  }
}
