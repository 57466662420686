import * as React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { stack as Menu, State } from 'react-burger-menu';
import { Link } from 'gatsby';
import MenuItems, { MenuItem } from './menuItems';

interface HeaderState {
  isMenuOpen: boolean
}

class Header extends React.Component<{}, HeaderState> {
  constructor (props: any) {
    super(props)
    this.state = {
      isMenuOpen: false
    }
  }

  private menuItemFilterFunction = (menuItem: MenuItem) => menuItem.isMainNavigation;

  // Keeps state in sync with the opening/closing of the menu
  // via the default means (for instance hitting the ESC button).
  private handleMenuStateChange(): ((state: State) => void) {
    return (state) => this.setState({isMenuOpen: state.isOpen});
  }

  private closeMenu(): ((event: any) => void) {
    return (event: any) => {
      this.setState(_ => ({ isMenuOpen: false }))
    };
  }

  render() {
    return (
      <nav className={`navbar navbar-expand-xl navbar-dark fixed-top px-0 py-2 shadow`}>
        <div className="mx-sm-0 mx-md-3">
          <AnchorLink className={`navbar-brand navbar-brand-image navbar-brand-image mx-3 mt-2`} to="/#top" stripHash={true} />
        </div>

        <Menu
          right={true}
          customCrossIcon={false}
          isOpen={this.state.isMenuOpen}
          disableAutoFocus={true}
          onStateChange={this.handleMenuStateChange()}
        >
          <ul className="list-unstyled">
            <MenuItems menuItemFeaturedClass="text-primary" menuItemFilter={this.menuItemFilterFunction} itemClickEvent={this.closeMenu()} />
          </ul>
        </Menu>

        <div className="collapse navbar-collapse text-uppercase text-right px-3">
          <ul className={`navbar-nav nav-list ml-auto mx-2`} data-toggle="collapse" data-target=".navbar-collapse">
            <MenuItems menuItemFeaturedClass="text-primary" menuItemFilter={this.menuItemFilterFunction} itemClickEvent={null} />
          </ul>
        </div>
      </nav>
    )
  }
}

export default Header;