import * as React from 'react';
import SocialIcons from './social-icons';
import MenuItems, { MenuItem } from './menuItems';
import ArrowIcon from './arrow-icon';

interface FooterProps {
  isFixedBottom?: boolean
  isSitemapHidden?: boolean
  isAddressInfoHidden?: boolean
  location: any;
}

export default class Footer extends React.Component<FooterProps, {}> {
  private menuItemFilterFunction = (menuItem: MenuItem) => !!menuItem;

  public render() {
    return (
      <footer id="footer" className={this.props.isFixedBottom ? 'fixed-bottom' : ''}>
        <section className={`footer-top pb-0 ${this.props.isSitemapHidden ? 'd-none' : 'd-block'}`}>
          <div className="d-flex flex-column w-100 h-100 p-4 text-center">
            <SocialIcons isHorizontal={true} className="flex-wrap mx-auto mb-2" />
            {/* <button onClick={ml_account('webforms', '2818435', 'r3l8q8', 'show')}>
              Click here to show popup
            </button> */}
            <nav className="container nav d-flex justify-content-center mt-auto mb-0 px-2 text-uppercase">
              <MenuItems menuItemClass="text-light" menuItemFilter={this.menuItemFilterFunction} itemClickEvent={null} />
            </nav>
            <ArrowIcon isPointingDown={false} anchorLinkTo={`${this.props.location?.pathname}#top`} />
          </div>
        </section>
        <section className={`w-100 p-4 bg-primary text-center ${this.props.isAddressInfoHidden ? 'd-none' : 'd-block'}`}>
          <h4 className="text-white">Jubal Dordrecht</h4>
          <h5 className="font-weight-bold">Dordrecht - Nederland</h5>
          <span>
            Opgericht in 1911<br />
            Jan Ligthartlaan 1 - 3312KD Dordrecht
          </span>
        </section>
        <div id="bottom-bar" className="bg-black w-100 mt-auto px-3 px-sm-5 py-3 py-sm-4 d-flex justify-content-between align-items-center text-primary">
          <a className="web-image" href="https://www.jubal.org" />
          <small>&copy; Jubal Dordrecht {new Date().getFullYear()}</small>
          <div className="brand-image d-none d-sm-block" />
        </div>
      </footer>
    );
  }
}
