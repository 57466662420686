import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faYoutubeSquare, faFlickr, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import styles from './social-icons.module.scss';
import { Link } from 'gatsby';

export interface SocialIconProps {
  isHorizontal: boolean
  className: string
}

export default class SocialIcons extends React.Component<SocialIconProps> {

  public render() {
    return (
      <div
        className={`d-flex ${this.props.className} ${this.props.isHorizontal ? 'flex-rows sal-disabled' : 'flex-column'} text-center sal-disabled-below-md`}
        data-sal="slide-left"
        data-sal-delay="300"
        data-sal-duration="1000"
        data-sal-easing="easy"
      >
        <a
          className={styles.socialIcon}
          href="https://facebook.com/JubalDordrecht/"
          title="Facebook"
          target="_blank"
        >
          <FontAwesomeIcon icon={faFacebookSquare} />
        </a>
        <a
          className={styles.socialIcon}
          href="https://instagram.com/JubalDordrecht/"
          title="Instagram"
          target="_blank"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          className={styles.socialIcon}
          href="https://youtube.com/JubalDordrecht/"
          title="YouTube"
          target="_blank"
        >
          <FontAwesomeIcon icon={faYoutubeSquare} />
        </a>
        <a
          className={styles.socialIcon}
          href="https://flickr.com/JubalDordrecht/"
          title="Flickr"
          target="_blank"
        >
          <FontAwesomeIcon icon={faFlickr} />
        </a>
        {/* <a
          className={styles.socialIcon}
          href="https://linkedin.com/company/JubalDordrecht/"
          title="LinkedIn"
          target="_blank"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a> */}
        <Link
          to="/flitsen"
          title="Jubal Flitsen"
          className={styles.socialIcon}
        >
          <div className={styles.flitsenIcon} />
        </Link>
      </div>
    );
  }
}
