import * as React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { StaticQuery, graphql, Link } from 'gatsby';

export interface Menudata {
  edges: ({
    node: MenuItem
  })[]
}

export interface MenuItem {
  name: string,
  link: string,
  type: string,
  isMainNavigation: boolean,
  isFeatured: boolean,
}

export function renderMenuItems(menuItemClass: string, menuItemFeaturedClass: string, menuItemFilter: ((value: MenuItem, index: number) => boolean), itemClick: any) {
  return (data: {allMenudataJson: Menudata}) => {
    return data.allMenudataJson.edges
      .map(({node}) => node)
      .filter(menuItemFilter)
      .map(menuItem => {
        if (menuItem.type === 'anchor') {
          return (
            <li key={menuItem.link} className="nav-item" onClick={itemClick}>
              <AnchorLink className={`nav-link ${menuItemClass} ${menuItem.isFeatured ? menuItemFeaturedClass : ''}`} stripHash={true} to={menuItem.link} title={menuItem.name}>{menuItem.name}</AnchorLink>
            </li>
          )
        } else if (menuItem.type === 'internal') {
          return (
            <li key={menuItem.link} className="nav-item" onClick={itemClick}>
              <Link className={`nav-link ${menuItemClass} ${menuItem.isFeatured ? menuItemFeaturedClass : ''}`} to={menuItem.link} title={menuItem.name}>{menuItem.name}</Link>
            </li>
          )
        } else if (menuItem.type === 'external') {
          return (
            <li key={menuItem.link} className="nav-item" onClick={itemClick}>
              <a className={`nav-link ${menuItemClass} ${menuItem.isFeatured ? menuItemFeaturedClass : ''}`} href={menuItem.link} title={menuItem.name}>{menuItem.name}</a>
            </li>
          )
        }
      })
  }
}

const MenuItems: React.SFC<{menuItemClass?: string, menuItemFeaturedClass?: string, menuItemFilter: ((value: MenuItem, index: number) => boolean), itemClickEvent: any}> = ({
  menuItemClass,
  menuItemFeaturedClass,
  menuItemFilter,
  itemClickEvent
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMenudataJson {
            edges {
              node {
                name
                link
                type
                isMainNavigation
                isFeatured
              }
            }
          }
        }`
      }
      render={renderMenuItems(menuItemClass,menuItemFeaturedClass, menuItemFilter, itemClickEvent)}
    />
  );
}

export default MenuItems;
